<template>
  <PacvuePopover trigger="hover" @show="syncHoverShow" @hide="syncMoveOuthide" popper-class="sync-popover">
    <template #reference>
      <div class="text-20px w-[20px]">
        <el-icon class="cursor-pointer mr-5">
          <PacvueIconSynchronization />
        </el-icon>
      </div>
    </template>
    <div class="h-[56px] px-[16px] py-[14px] border-b-[1px] border-b-solid border-b-[#E9E9E9] flex flex-row justify-between">
      <span class="text-[16px] font-bold leading-[18px] self-center">{{ $t("amskey11") }}</span>
      <div class="text-[#FF9F43] text-[12px] leading-[18px] font-bold self-center cursor-pointer" @click="viewAll()">{{ $t("key1735") }}</div>
    </div>
    <PacvueTab :tab-position="'top'" class="demo-tabs" v-model="currentTab" @tab-change="tabChange" v-if="hasdisplayprofile">
      <el-tab-pane :label="$t('Sponsored Search')" name="0"></el-tab-pane>
      <el-tab-pane :label="$t('Display')" name="1"></el-tab-pane>
    </PacvueTab>
    <div class="showbox p-[16px]" :class="hasdisplayprofile ? 'h-[200px]' : 'h-[250px]'" v-pacvueLoading="loading.contentLoading">
      <div v-if="showData.length > 0">
        <div v-for="item in showData" class="h-[80px] pb-[8px] w-[240px]">
          <div class="h-[24px] pb-[4px] truncate text-[14px] text-[#45464F]">
            {{ item.profileName }}
          </div>
          <div class="h-[24px] pb-[4px] truncate text-[12px]">
            {{ item.type }}
          </div>
          <div class="h-[24px] pb-[4px] truncate text-[12px]">
            {{ item.time }}
          </div>
        </div>
      </div>

      <div v-else class="flex h-[200px] w-[280px] justify-center">
        <el-empty :image-size="80" description="No data found!" />
      </div>
    </div>
  </PacvuePopover>
</template>

<script setup>
import { ref, onMounted, reactive, computed } from "vue"
import { useCommonStore } from "@/store"
import { useUserInfo } from "@/utils/common-util"
import { useRouter } from "vue-router"
import { getLastSyncInfoList } from "@!/api/Advertising/AsyncData.js"
const router = useRouter()
var userInfo = useUserInfo()
const commonStore = useCommonStore()
const timeType = userInfo.dateFormat.value
const showData = ref([])
const matchDataType = reactive({
  CampaignSetting: $t("key1517"),
  CampaignReport: $t("Campaign Report"),
  CampaignRealTimeData: $t("key175"),
  AdGroupSetting: $t("key63"),
  AdGroupReport: $t("Adgroup Report"),
  KeywordSetting: $t("Keyword Setting"),
  SuggestKeywordsAndBid: $t("Suggest Keywords & Bid"),
  KeywordReport: $t("Keyword Report"),
  ItemSetting: $t("Item Setting"),
  ItemReport: $t("Item Report"),
  SearchTermReport: $t("Search Term Report"),
  DisplayCampaignSetting: $t("key1517"),
  DisplayCampaignReport: $t("Campaign Report"),
  DisplayAdGroupSetting: $t("key63"),
  DisplayAdGrouReport: $t("Adgroup Report"),
  DisplayTargetingTacticReport: $t("Targeting Tactic Report"),
  DisplayItemReport: $t("Item Report"),
  DisplayBidReport: $t("Bid Report"),
  DisplayNewBuyersReport: $t("New Buyers Report")
})
const loading = reactive({
  contentLoading: false
})
//tab
const currentTab = ref("0")
const tabChange = () => {
  showData.value = []
  getData()
}

const syncMoveOuthide = () => {
  showData.value = []
}
const groupData = (data) => {
  showData.value = []
  let _timeType = timeType + " HH:MM"
  data?.forEach((item) => {
    let obj = {
      profileName: item.profileName
    }
    obj["type"] = matchDataType[item.actionName]
    obj["time"] = item.syncCompleteTimeStr ? item.syncCompleteTimeStr + " (PST)" : "--"
    showData.value.push(obj)
  })
}
const getData = () => {
  loading.contentLoading = true
  getLastSyncInfoList(currentTab.value)
    .then((res) => {
      console.log("popover--->res-->", res)
      loading.contentLoading = false
      groupData(res)
    })
    .catch((err) => {
      loading.contentLoading = false
      groupData([])
    })
}
const viewAll = () => {
  const { href } = router.resolve({ path: "/AsyncData" })
  window.open(href, "_blank")
}
const allProfileIds = ref([])
const profileSelections = ref([])
const syncHoverShow = () => {
  commonStore.getProfileList().then((data) => {
    profileSelections.value = data
    data.forEach(({ id }) => {
      allProfileIds.value.push(id)
    })
    getData()
  })
}
const hasdisplayprofile = computed(() => {
  console.log
  return profileSelections.value.filter((item) => {
    return item.isDisplayType
  }).length
})
onMounted(() => {})
</script>

<style lang="scss" scoped>
.showbox {
  overflow-y: scroll;
  overflow-x: hidden;
}
:deep(.el-tabs__nav-scroll) {
  padding: 0 16px;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #d2d4d9;
}
::-webkit-scrollbar-track {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0);
}
</style>
