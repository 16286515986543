import * as storeModule from "../index"
import { getUseStoreKey } from "@pacvue/store/utils/util"
import { strCapitalize } from "@pacvue/store/utils/util"
export default {
  platformName(state) {
    var useStoreKey = getUseStoreKey(state.platform)
    if (storeModule[useStoreKey]) {
      var store = storeModule[useStoreKey]()
      return store.platformName
    }
    return state.platform
  },
  curStore(state) {
    var storeName = `use${strCapitalize(state.platform)}Store`
    var curStore = storeModule[storeName]()
    return curStore
  },
  selectedProfiles(state) {
    return this.curStore.selectedProfiles || []
  },
  selectedCamTags(state) {
    return this.curStore.selectedCamTags || []
  },
  selectedPortfolios(state) {
    return this.curStore.selectedPortfolios || []
  },
  selectedCampaigns(state) {
    return this.curStore.selectedCampaigns || []
  },
  selectedAdgroups(state) {
    return this.curStore.selectedAdgroups || []
  },
  adProfileList() {
    return this.curStore.profileList || []
  },
  adProfileIds() {
    return this.adProfileList.map((item) => {
      return item.id
    })
  },
  adAllProfileList() {
    return this.curStore.allProfileList || []
  },
  adAllProfileIds() {
    return this.adAllProfileList.map((item) => {
      return item.id
    })
  },
  commonSelFilter() {
    return this.curStore.commonSelFilter
  },
  commonfilters() {
    return this.curStore.commonfilters
  },
  hasCommonFiltersCache() {
    var commonfiltersMap = this.commonfilters || {}
    if (Object.keys(commonfiltersMap).length) {
      return true
    }
    return false
  },
  hasExplorerCommonFiltersCache() {
    var commonfiltersMap = this.explorerCommonfilters || {}
    if (Object.keys(commonfiltersMap).length) {
      return true
    }
    return false
  }
}
