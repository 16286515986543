import request from "@pacvue/frame/request"
const { VITE_APP_WALMART_BASE_URL } = import.meta.env

export function getAsyncTableData(data) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "SyncData/SyncInfoList",
    data,
    method: "post"
  })
}

export function getLastSyncInfoList(type) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "SyncData/LastSyncInfoList?queryType=" + type,
    method: "get"
  })
}

export function postBulkSyncData(data) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "SyncData/BulkSyncData",
    data,
    method: "post"
  })
}

export function chcekSyncData(data) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "SyncData/CheckSyncData",
    data,
    method: "post"
  })
}
