import { createPinia } from "pinia"
import { StorePersistPlugin, PERSIST_KEY_PRE as persistPrevKey } from "@pacvue/store/utils/store-persist-plugin"

export * from "./modules/index"
export const PERSIST_KEY_PRE = persistPrevKey
export default function createCustomPinia() {
  const pinia = createPinia()
  pinia.use(StorePersistPlugin)
  return pinia
}
