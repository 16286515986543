export default {
  retailer: true,
  account: true,
  swtichOld: false,
  University: true,
  Training: true,
  Feedback: {
    projectKey: "PW",
    selectedVersion: "New UI Feedback"
  },
  issueClientIds: [1145],
  Message: true,
  Download: true,
  MessageTab: ["All", "Manual Task", "System", "Data Download"],
  MyAccount: true,
  Synchronization: false,
  Knowledge: true,
  UserManage: true,
  Settings: true,
  Notification: true,
  UserCenter: true,
  maidian: {},
  analytics: true,
  Copilot: true,
  CopilotFeatures: ["Feature Instruction", "Data Query"],
  isNewLocaleAsync: true,
  Language: [
    {
      id: "EN",
      url: "https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/country/US.png",
      name: "English"
    },
    {
      id: "ZH",
      url: "https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/country/ZH.png",
      name: "中文"
    }
  ],
  // 采集测试实例配置[applicationId, clientToken, service, blacklist<"1,2,3">]，默认空则启用生产实例
  ddConfig: [],
  coralogixConfig: [],
  isNewRouterAsync: true
}
