import { useAppStore } from "@pacvue/store"
import { useUserStore } from "@pacvue/store"
import rule from "./rule.js"
import common from "./common/index"
import amazon from "./amazon/index"
import walmart from "@!/store/modules/walmart/index"
import { useStoreModule } from "@pacvue/store/utils/util"
const modules = { common, amazon, walmart, rule }
var { useCommonStore, useAmazonStore, useWalmartStore, useRuleStore } = useStoreModule(modules)

export { useAppStore, useUserStore, useCommonStore, useAmazonStore, useWalmartStore, useRuleStore }
