//import modules from './modules/index'
import { dayjs } from "@pacvue/element-plus"

const watchFields = [
  "selectedProfiles",
  "selectedCampaignTypes",
  "sovOrder",
  "itemOrder",
  "selectedCamTags",
  "selectedPorfolios",
  "selectedCampaigns",
  "selectedAdgroups",
  "defaultReportQuery",
  "commonfilters",
  "explorerCommonfilters"
]

export default {
  state: () => {
    return {
      platformName: "walmart",
      profileList: [],
      profileListAccount: [],
      allProfileList: [],
      selectedProfiles: [],
      selectedCampaignTypes: [],
      sovOrder: "shareOfShelf",
      itemOrder: "shareOfShelf",
      intelligenceProfile: [],
      intelligenceTopData: "",
      selectedCamTags: [],
      selectedPortfolios: [],
      selectedCampaigns: [],
      selectedAdgroups: [],
      sdProfiles: [],
      mpReportFilters: {},
      mpLoading: false,
      commonfilters: {},
      explorerCommonfilters: {},
      ItemInsightfilter: {},
      LowHealthDialog: false,
      sovReadOnly: true, // 默认true，sov相关操作禁用
      sovRankingBrands: [], // sov排名的brand
      sovAllBrands: [], // sov所有的brands-常用于条件搜索
      profilesMap: {}, // 根据countrycode获取的profile
      sovDaypartDate: {
        compare: false,
        date: {
          startDate: dayjs().subtract(6, "day").format("MM/DD/YYYY"),
          endDate: dayjs().format("MM/DD/YYYY")
        },
        compareDate: {
          start: "",
          end: ""
        },
        // min: dayjs().subtract(60, "day"),
        latestDateMode: "Last 7 Days"
      },
      productDashboardDate: {
        date: {
          startDate: dayjs().subtract(29, "days").format("MM/DD/YYYY"),
          endDate: dayjs().format("MM/DD/YYYY")
        },
        compareDate: {
          start: dayjs().subtract(59, "days").format("MM/DD/YYYY"),
          end: dayjs().subtract(30, "days").format("MM/DD/YYYY")
        }
      },
      productDashboardShareDateLimit: undefined,
      productDashboardChannel: undefined,
      productDashboardShareChannel: undefined
    }
  },
  getters: {
    //testName: (state) => state.advertising.testName
  },
  actions: {
    TEST_FN() {
      console.error("我的平台是：" + this.platformName)
    },
    setProductDashboardDate(playload) {
      this.productDashboardDate = playload || {}
    },
    setDefaultReportQuery(playload) {
      this.defaultReportQuery = playload || {}
    },
    setProfileList(playload) {
      this.profileList = playload || []
    },
    setProfileListAccount(payload) {
      this.profileListAccount = payload || []
    },
    setAllProfileList(playload) {
      this.allProfileList = playload || []
    },
    setSelectedProfiles(playload) {
      var profileIds = playload || []
      this.selectedProfiles = profileIds
    },
    setSelectedCampaignTypes(playload) {
      var campaignTypes = playload || []
      this.selectedCampaignTypes = campaignTypes
    },
    setItemOrder(val) {
      this.itemOrder = val
    },
    setSovOrder(val) {
      this.sovOrder = val
    },
    setIntelligenceProfile(val) {
      this.intelligenceProfile = val
    },
    setIntelligenceTopData(val) {
      this.intelligenceTopData = val
    },
    setSelectedCamTags(playload) {
      var CampaignTags = playload || []
      this.selectedCamTags = CampaignTags
    },
    setSelectedPortfolios(playload) {
      var Porfolios = playload || []
      this.selectedPortfolios = Porfolios
    },
    setSelectedCampaigns(playload) {
      var Campaigns = playload || []
      this.selectedCampaigns = Campaigns
    },
    setSelectedAdgroups(playload) {
      var Adgroups = playload || []
      this.selectedAdgroups = Adgroups
    },
    setUserPersist() {
      var userid = localStorage.getItem("uid")
      watchFields.forEach((fieldName) => {
        var storeKey = userid + "_" + fieldName
        var storeStr = localStorage.getItem(storeKey)
        if (storeStr) {
          this[fieldName] = JSON.parse(storeStr)
        }
      })
    },
    setSdProfiles(playload) {
      this.sdProfiles = playload || []
    },
    updateItemInsightfilter(playload) {
      this.ItemInsightfilter = playload
    },
    openLowHealthDialog(playload) {
      this.LowHealthDialog = playload
    },
    setCommonFilters(playload) {
      //设置页面公用的filter变量
      playload = playload || {}
      this.commonfilters = { ...this.commonfilters, ...playload }
    },
    setExplorerCommonFilters(playload) {
      //设置页面公用的filter变量
      playload = playload || {}
      this.explorerCommonfilters = { ...this.explorerCommonfilters, ...playload }
    },
    SET_PROFILESMAP(key, data) {
      this.profilesMap[key] = data
    },
    SET_SOVDAYPARTDATE(value, key) {
      localStorage.setItem(key, JSON.stringify(value))
      this.sovDaypartDate = value
    }
  },
  //开启数据缓存
  persist: {
    enabled: true,
    watchFields: watchFields
  }
}
