import { getCountryCode } from "@/api/DefaultReport/marketplace.js"
import { dayjs } from "@pacvue/element-plus"

const watchFields = ["selectedProfiles", "sovOrder", "itemOrder", "selectedCamTags", "selectedPorfolios", "selectedCampaigns", "selectedAdgroups", "defaultReportQuery"]

export default {
  state: () => {
    return {
      platformName: "amazon",
      profileList: [],
      allProfileList: [],
      selectedProfiles: [],
      sovOrder: "shareOfShelf",
      itemOrder: "shareOfShelf",
      intelligenceProfile: [],
      intelligenceTopData: "",
      selectedCamTags: [],
      selectedPortfolios: [],
      selectedCampaigns: [],
      selectedAdgroups: [],
      sdProfiles: [],
      mpReportFilters: {},
      productDashboardDate: {
        date: {
          startDate: dayjs().subtract(29, "days").format("MM/DD/YYYY"),
          endDate: dayjs().format("MM/DD/YYYY")
        },
        compareDate: {
          start: dayjs().subtract(59, "days").format("MM/DD/YYYY"),
          end: dayjs().subtract(30, "days").format("MM/DD/YYYY")
        }
      },
      productDashboardShareDateLimit: undefined,
      productDashboardChannel: undefined,
      productDashboardShareChannel: undefined,
      productListingChannel: "",
      productListing: {
        dateRange: {}
      },
      mpLoading: false,
      accountAuditReportCountryCode: ""
    }
  },
  getters: {
    //testName: (state) => state.advertising.testName
  },
  actions: {
    TEST_FN() {
      console.error("我的平台是：" + this.platformName)
    },
    setProductDashboardDate(playload) {
      this.productDashboardDate = playload || {}
    },
    setDefaultReportQuery(playload) {
      this.defaultReportQuery = playload || {}
    },
    setProfileList(playload) {
      this.profileList = playload || []
    },
    setAllProfileList(playload) {
      this.allProfileList = playload || []
    },
    setSelectedProfiles(playload) {
      var profileIds = playload || []
      this.selectedProfiles = profileIds
    },
    setSovOrder(val) {
      this.sovOrder = val
    },
    setItemOrder(val) {
      this.itemOrder = val
    },
    setIntelligenceProfile(val) {
      this.intelligenceProfile = val
    },
    setIntelligenceTopData(val) {
      this.intelligenceTopData = val
    },
    setSelectedCamTags(playload) {
      var CampaignTags = playload || []
      this.selectedCamTags = CampaignTags
    },
    setSelectedPortfolios(playload) {
      var Porfolios = playload || []
      this.selectedPortfolios = Porfolios
    },
    setSelectedCampaigns(playload) {
      var Campaigns = playload || []
      this.selectedCampaigns = Campaigns
    },
    setSelectedAdgroups(playload) {
      var Adgroups = playload || []
      this.selectedAdgroups = Adgroups
    },
    setUserPersist() {
      var userid = localStorage.getItem("uid")
      watchFields.forEach((fieldName) => {
        var storeKey = userid + "_" + fieldName
        var storeStr = localStorage.getItem(storeKey)
        if (storeStr) {
          this[fieldName] = JSON.parse(storeStr)
        }
      })
    },
    setSdProfiles(playload) {
      this.sdProfiles = playload || []
    },
    setMpReportFilters(playload) {
      // console.log("lht--->store--->", playload)
      this.mpLoading = true
      let obj = JSON.parse(JSON.stringify(playload))
      if (playload["ProfileIds"].length === 0) {
        obj["SortCountryCode"] = []
        this.mpReportFilters = obj
        this.mpLoading = false
        return
      }
      let param = {
        CampaignTags: playload["CampaignTags"],
        CampaignTypes: playload["CampaignTypes"],
        EndDate: playload["EndDate"],
        ProfileIds: playload["ProfileIds"],
        RateCountryCode: playload["RateCountryCode"],
        StartDate: playload["StartDate"]
      }
      getCountryCode(param).then((res) => {
        obj["SortCountryCode"] = res.CountryCode
        obj["IsExchange"] = 1
        this.mpReportFilters = obj
        this.mpLoading = false
      })
    },
    setProductListing(key, value) {
      this.productListing[key] = value
    },
    setProductListingChannel(playload) {
      this.productListingChannel = playload
    },
    setAccountAuditReportCountryCode(playload) {
      this.accountAuditReportCountryCode = playload
    }
  },
  //开启数据缓存
  persist: {
    enabled: true,
    watchFields: watchFields
  }
}
